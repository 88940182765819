import {useState} from 'react'; 
import {
  Modal,
  Button,
  ButtonContent,
  ButtonGroup,
  Divider,
  Icon
} from "semantic-ui-react";


function SupportModal(props) {

  const [open, setOpen] = useState(false);

  const handleClose = () => props.onClose();

  return (
    <Modal open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} trigger={props.trigger} size='tiny' >
      <Modal.Header>Support</Modal.Header>
      <Modal.Content image>
        <Icon size='massive' name="smile" />
        <Modal.Description>
          <p>Orting Police Department</p>

          <Divider horizontal>Visit our Website!</Divider>
          <a href="https://cityoforting.org/police"><Button ><Icon name="github"/>On the Web</Button></a>
          
          

        </Modal.Description>

      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} >
          Done
        </Button>
      </Modal.Actions>
    </Modal>

  )

}

export default SupportModal;
