import React, {useState} from "react";
import { Link } from 'react-router-dom'
import { Container, Header, Divider, Icon, Menu, Button,  Accordion, } from "semantic-ui-react";


const AboutComponent = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }

  const panels = [
    {
      key: 'feeds',
      title: {content: (<span style={{fontWeight: "bold"}}>Where do the recordings come from?</span>)},
      content: [
        'Radio traffic is captured by a software sefined radio (SDR) connected to a server that captures voice traffic being transmitted by the local radio system.  Server software then sends the transmissions to this website.',
      ].join(' '),
    }
  ]
  

  return (
    <div>
      <Menu fixed="top">
        <Link to="/"><Menu.Item link><Icon name='arrow left' /> Home</Menu.Item></Link>
        <Link to="/systems"><Menu.Item link>Listen</Menu.Item></Link>
      </Menu>
      <Container text>
        <Divider horizontal style={{ paddingTop: "4em", paddingBottom: "2em" }}><Header as="h1">About</Header></Divider>

        <p>This site is based upon trunk-recorder, a P25 radio capturing open source project by Luke Brandt</p>

        <p>The audio from each system is archived for 30 days, so you can go back and listen to events you may have missed.</p>
        <p>  </p>

        <Divider horizontal style={{ paddingTop: "4em", paddingBottom: "2em" }}><Header as="h2">100% Open Source</Header></Divider>
        <p></p>
        <p></p>
        <Divider horizontal style={{ paddingTop: "4em", paddingBottom: "2em" }}><Header as="h2">Support</Header></Divider>
        <p></p>

        <p></p>

        <Divider horizontal style={{ paddingTop: "4em", paddingBottom: "2em" }}><Header as="h2">FAQ</Header></Divider>
        <Accordion defaultActiveIndex={0} panels={panels} />
        <Divider/>
      </Container>
    </div>
  );
}


export default AboutComponent;
